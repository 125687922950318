const host = "127.0.0.1";

export default {
  appName: "Qualy",
  googleApiKey: "AIzaSyC0POAHa9__OO7WLBWck9T4u6R-qQv-T-4",
  appURL:
    process.env.NODE_ENV === "development"
      ? "https://dev.qualy.co.uk"
      : "https://dev.qualy.co.uk",
  appAPIBase:
    process.env.NODE_ENV === "development"
      ? `https://dev-api.qualy.co.uk`
      : "https://dev-api.qualy.co.uk",
  appAPI:
    process.env.NODE_ENV === "development"
      ? `https://dev-api.qualy.co.uk/api`
      : "https://dev-api.qualy.co.uk/api",
  appAPIAssets: "https://qualy.assets.s3.eu-west-2.amazonaws.com/",
  user: {},
  ADMIN_MAIL: "tony@qualy.co.uk",
};

// export default {
//   appName: "Qualy",
//   googleApiKey: "AIzaSyC0POAHa9__OO7WLBWck9T4u6R-qQv-T-4",
//   appURL:
//     process.env.NODE_ENV === "development"
//       ? "http://localhost:3000"
//       : "https://dev.qualy.co.uk",
//   appAPIBase:
//     process.env.NODE_ENV === "development"
//       ? "http://127.0.0.1:8890"
//       : "https://dev-api.qualy.co.uk",
//   appAPI:
//     process.env.NODE_ENV === "development"
//       ? "http://127.0.0.1:8081/api/v1.0"
//       : "https://dev-api.qualy.co.uk/api/v1.0",
//   appAPIAssets: "https://qualy.assets.s3.eu-west-2.amazonaws.com/",
//   user: {},
//   ADMIN_MAIL: "tony@qualy.co.uk",
// };
